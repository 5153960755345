import { Link } from 'react-scroll';

import '../styles/About.css';

const details = () => {
  return (
    <div className='section'>
      <span className='sectionLine'>
        <Link activeClass='active' to='education' spy={true} smooth={true} duration={1000}>
          Education
        </Link>
      </span>
      <span className='sectionLine'>
        <Link activeClass='active' to='projects' spy={true} smooth={true} duration={1000}>
          Projects
        </Link>
      </span>
      <span className='sectionLine'>
        <Link activeClass='active' to='skills' spy={true} smooth={true} duration={1000}>
          Skills
        </Link>
      </span>
      <span className='sectionLine'>
        <Link activeClass='active' to='work' spy={true} smooth={true} duration={1000}>
          Experience
        </Link>
      </span>


      <span className='sectionLine'>
        <Link activeClass='active' to='courses' spy={true} smooth={true} duration={1000}>
          Courses
        </Link>
      </span>

    </div>
    
  );
};

export default details;