import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../styles/About.css';

const intro = () => {
  return (
    <>
      <div className='intro'>
        <span className='introName'>Anuinder Sekhon</span>
        {/* <span className='introLine'>Graduate Data Science Student at University of Victoria </span> */}
      </div>

      <div className='about'>
        <p className='aboutLine'>
          As a student with specialization in Applied Data Science at University of Victoria, I have strong problem-solving skills with statistical knowledge, passion to explore data to extract useful insights and software development experience.
      </p>
        <p className='aboutLine'>
        I am part of reseach project at <a href='https://bcace.org/justice-data-and-design-lab' target='_blank'>Justice Data and Design Lab </a>, UVic Law, where research focuses on applying the machine learning technqiues for analyzing  the publicly available Britsh Columbia  legal data to improve Access to Justice. This project is funded by BC Law Foundation and MITACS. 
        </p>
        <p className='aboutLine'>
          <span>
            <FontAwesomeIcon icon={faEnvelope} style={{ height: 20, width: 20, borderRadius: 50 }} />
          </span>
          <span> anuindersekhon@uvic.ca </span>
        </p>
      </div>
    </>
  );
};

export default intro;
