import React from 'react';

import '../styles/Project.css';

const projects = () => {
  const education = [
    ['Master of Engineering', 'University of Victoria, Canada', 'Applied Data Science'],
    ['Bachelor of Engineering', 'Chitkara University, India', 'Computer Science and Engineering'],
  ];
  const work = [
    'Research Engine Developer - UVic Law ',
    'Teaching Assistant - UVic',
    'Software Engineer Intern - Rovi Corp, Bangalore',
    'Computer Science Educator',
  ];
  const courses = ['Optimization for Machine Learning, Data Mining, Applied Data Analysis', 'Applied Cryptography, Data Science and Software Engineering', 'Algorithms and Data Models, Numerical Methods and Statistical Analysis'];
  const skills = [
    'Python (with Pandas, Numpy, NTLK, Matplotlib,Seaborn), R, Java, C, Javascript',
    'Deep Learning with Tenserflow, Big Data: Hadoop Map Reduce, PySpark ',
    'Supervised and Unsupervsied machine learning techniques',
    'ReactJs, Python-Django, GCP, MATLAB, Git, SQL, MongoDB, Learning Design'
  ];
  const projects = [
    [
      'Generative AI for Data Science and Analytics Education',
      'Python',
      'Reseach Project to study accuracy of ChatGPT (GPT 3.5 model) in the courses related to data science and analytics. Currently working towards publication for the same.',
    ],
    [
      'Bayesian Modelling',
      'R Language',
      'Creating a Bayesian workflow  analysis  to assess software engineering outcomes using COCOMO model, using a previously published data',
    ],
    [
      'Raising CyberSecurity resilience with CyberSmart Trivia',
      'Adobe Captivate',
      'Ed-tech platform complaint with WCAG 2.0 and rigorously tested using Commix tool, which incorporates idea of  gamification to impart education field of network and security. Awarded with the Best Presentation Award by the Department',
    ],
    ['Time Series Analysis', 'Python', 'Ongoing project to implement time series analysis on the spotify dataset'],
    ['Textual Analysis', 'Python', 'Implemented the textual analysis of the imdb reviews dataset'],
    [
      'Weather Application',
      'ReactJS',
      'Responsive web interface to Search hourly and daily weather forcasts of various locations ',
    ],
    // ['Move Analysis', 'PYTHON', 'Analysis of tmdb movie dataset for various primary and critical features'],
    // ['Travel Guide', 'JAVA', 'Get details of various tourist cities of India'],
    // ['Notes', 'FLASK', 'Get Links to study resources for various courses'],
  ];
  return (
    <div className='details'>
      <div id='education'>
        <span className='heading'>EDUCATION</span>
        {education.map((p) => (
          <span className='educationline'>
            <span className='educationname'>{p[0]}</span>
            <span className='educationplace'>{p[1]}</span>
            <span className='educationdesc'>{p[2]}</span>
          </span>
        ))}
      </div>
      <div id='projects'>
        <span className='heading'>PROJECTS</span>
        {projects.map((p) => (
          <span className='projectLine'>
            <span className='projectname'>{p[0]}</span>
            <span className='projecttech'>{p[1]}</span>
            <span className='projectdesc'>{p[2]}</span>
          </span>
        ))}
      </div>
      <div id='skills'>
        <span className='heading'>SKILLS</span>
        {skills.map((p) => (
          <span className='skillLine'>{p} </span>
        ))}
      </div>
      <div id='work'>
        <span className='heading'>EXPERIENCE</span>
        {work.map((p) => (
          <span className='workline'>{p} </span>
        ))}
      </div>
      <div id='courses'>
        <span className='heading'>FEATURED COURSES</span>
        {courses.map((p) => (
          <span className='courseLine'>{p} </span>
        ))}
      </div>
      <div className='design'>
        Design inspired from{' '}
        <a href='https://dribbble.com/shots/9809111-Developer-Dark-Grid-Portfolio' target='_blank'>
          {' '}
          Nicolas Meuzard{' '}
        </a>
      </div>
    </div>
  );
};

export default projects;
