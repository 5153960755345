import './App.css';
import AboutMe from './components/About.js';
import Projects from './components/Projects.js';

function App() {
  return (
    <div className='App'>
      <div className='container'>
        <AboutMe />
        <Projects />
      </div>
    </div>
  );
}

export default App;
