import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope,faPen } from '@fortawesome/free-solid-svg-icons';


import '../styles/About.css';
import photo from '../assets/photo.jpg';

const contact = () => {
  return (
    <div className='contact'>
      <span className='contactLine'>
        <img src={photo} style={{ height: 65, width: 65, borderRadius: 50 }} />
      </span>
      <span className='contactLine'>
        <a href='https://github.com/Anuinder' target='_blank'>
          <FontAwesomeIcon icon={faGithub} style={{ height: 65, width: 65, borderRadius: 50 }} />
          {/* Github */}
        </a>
      </span>
      <span className='contactLine'>
        <a href='https://in.linkedin.com/in/anuinder-sekhon-b4aab4122' target='_blank'>
          <FontAwesomeIcon icon={faLinkedin} style={{ height: 65, width: 65, borderRadius: 50 }} />
          {/* Mail */}
        </a>
      </span>
      {/* <span className='contactLine'>
        <a href='mailto:anuindersekhon@uvic.ca'>
          <FontAwesomeIcon icon={faEnvelope} style={{ height: 65, width: 65, borderRadius: 50 }} />

        </a>
      </span> */}

      {/* <span className='contactLine'>
        <a href='https://twitter.com/AnuinderS' target='_blank'>
          <FontAwesomeIcon icon={faTwitter} style={{ height: 70, width: 70, borderRadius: 50 }}  />
          {/* Twitter */}
      {/* </a>
      </span> */}
    </div>
  );
};

export default contact;
