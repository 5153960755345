import React from 'react';

import Introduction from './Intro.js';
import Details from './Details.js';
import Contact from './Contact.js';

import '../styles/About.css';

const aboutMe = () => {
  return (
    <div className='aboutMe'>
      <Introduction />
      <Details />
      <Contact />
    </div>
  );
};

export default aboutMe;
